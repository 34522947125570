import React, { useCallback, useEffect, useState } from "react";
// import { faHeart } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import Stars from "../star/Stars";
import { CONSTANTES } from "../../global/CONSTANTES";
import { COLORS } from "../../global/STYLE";
import { homeService } from "../../services/home";
import { formatarValorReal } from "../../functions/formatarValorReal";
import { converteSegundos } from "../../functions/converteSegundos";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-toastify";
import AgendamentoModal from "./AgendamentoModal";
import LoginClienteModal from "../signIn_cliente/LoginClienteModal";
import noImage from "../../assets/images/noimage.png";
import { colaboradorService } from "../../services/colaborador";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageGallery from "./ImageGallery";
import OfertaList from "./OfertaList";
import CompartilharEstabelecimento from "./CompartilharEstabelecimento";
import ShareButton from "../redes_sociais/ShareButton";
import { estabelecimentoService } from "../../services/estabelecimento";

const Estabelecimento = ({ id, data, onClose }) => {
  const { userType, token, setIsLogado, isLogado } = useAuth();

  // console.log(userType, token);
  // console.log(id, data);
  const [estabelecimento, setEstabelecimento] = useState(data);
  const [menuAtivo, setMenuAtivo] = useState(0); // Estado para controlar o menu ativo
  const [servico, setServico] = useState([]);
  const [galeria, setGaleria] = useState([]);
  const [colaborador, setColaborador] = useState([]);
  const [oferta, setOferta] = useState([]);
  const [dadosAgendar, setDadosAgendar] = useState({});
  const [foto_bg, setFoto_bg] = useState(null);

  const [modalAberto, setModalAberto] = useState(false);

  const [modalAbertoLogin, setModalAbertoLogin] = useState(false);

  const [comentarios, setComentarios] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingComentario, setLoadingComentario] = useState(false);

  const abrirModalLogin = (id) => {
    setModalAbertoLogin(true);
  };

  const fecharModalLogin = () => {
    setModalAbertoLogin(false);
  };

  const {
    nome,
    star,
    logradouro,
    // foto_bg,
    numero,
    bairro,
    uf,
    cidade,
    favorito,
    liberar_agendamento,
    telefone,
  } = estabelecimento;

  useEffect(() => {
    if (estabelecimento.foto_bg) {
      setFoto_bg(estabelecimento.foto_bg);
    }
  }, [estabelecimento]);

  // let foto_bg = null

  // Função para definir o menu ativo
  const handleMenuClick = (index) => {
    setMenuAtivo(index);
  };

  const abrirModal = (id) => {
    setModalAberto(true);
  };

  const fecharModal = () => {
    setModalAberto(false);
  };

  //AGENDAMENTO
  const agendar = (serviceID, tituloServico) => {
    if (userType === "cliente") {
      // toast.info("abrir agendamento!")
      setDadosAgendar({
        visibility: true,
        estabelecimento_id: id,
        servico_id: serviceID,
        titulo_servico: tituloServico,
      });
      abrirModal(true);
    } else {
      toast.info(
        "Para agendar um serviço, você precisa fazer o login como cliente!"
      );
      setModalAbertoLogin(true);
    }
  };

  //PEGA DADOS USUARIO LOGADO
  const pegaDados = useCallback(async () => {
    try {
      const response = await homeService.getEstabelecimentoSemLogin(
        id,
        "ativado"
      );
      if (!response.error) {
        const data = response.data;
        // setColaborador(data.colaboradores);
        setGaleria(data.galeria);
        setOferta(data.oferta);
        setServico(data.servicos);
        // console.log(data.galeria);
        // console.log(data);

        const dadoColaborador =
          await colaboradorService.listaColaboradorPorEstabelecimento(
            token,
            id,
            "ativado"
          );

        if (dadoColaborador.error) {
          if (dadoColaborador.reload) {
            // setLoading(false);
            setIsLogado(false); //setIsAuthenticated(false)
            return;
          }
        } else {
          setColaborador(dadoColaborador.data);

          // const dadoGaleria = await galeriaService.listGaleriaEstabelecimento(estabelecimento_id,'ativado')
          // if(dadoGaleria.error){
          //   console.log(dado.message)
          // }else{
          //   setGaleria(dadoGaleria.data)
          // }
        }
      } else {
        if (response.reload) {
          // setLoading(false);
          setIsLogado(false); //setIsAuthenticated(false)
          return toast.error(response.message);
        }
        // console.log(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  // Função para pegar comentários paginados
  // Função para pegar comentários paginados
  const pegaDadosComentarios = useCallback(
    async (pagina = 1) => {
      setLoadingComentario(true);

      try {
        const dadoComentario = await estabelecimentoService.buscaComentarios(
          id,
          pagina
        );
        if (!dadoComentario.error) {
          setComentarios(dadoComentario.data);
          setTotalPages(dadoComentario.totalPages);
          setPage(pagina);
        }
      } catch (e) {
        console.log("Erro: ", e.message);
      } finally {
        setLoadingComentario(false);
      }
    },
    [id]
  );

  // Carrega os dados de comentários quando o componente é montado
  useEffect(() => {
    pegaDadosComentarios(1); // Carrega a primeira página ao iniciar
  }, [pegaDadosComentarios]);

  useEffect(() => {
    pegaDados();
  }, [pegaDados, isLogado]);

  useEffect(() => {
    // Função para adicionar classe ao body ao abrir o modal
    const disableScroll = () => {
      document.body.style.overflow = "hidden";
    };

    // Função para remover classe ao fechar o modal
    const enableScroll = () => {
      document.body.style.overflow = "auto";
    };

    // Chamada ao montar o componente
    disableScroll();

    // Retorno para limpar a função ao desmontar o componente
    return () => {
      enableScroll();
    };
  }, []);

  const renderPaginacao = () => {
    const paginas = [];
    for (let i = 1; i <= totalPages; i++) {
      paginas.push(
        <button
          key={i}
          onClick={() => pegaDadosComentarios(i)}
          disabled={i === page}
          style={{
            margin: "0 8px",
            padding: "8px 16px",
            backgroundColor: i === page ? COLORS.star : "#fff",
            color: i === page ? "#fff" : "#333",
            border: "1px solid #ddd",
            borderRadius: "5px",
            cursor: i === page ? "not-allowed" : "pointer",
            transition: "all 0.3s ease",
            boxShadow: i === page ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "none",
          }}
          onMouseEnter={(e) => {
            if (i !== page) {
              e.target.style.backgroundColor = "#f0f0f0";
            }
          }}
          onMouseLeave={(e) => {
            if (i !== page) {
              e.target.style.backgroundColor = "#fff";
            }
          }}
        >
          {i}
        </button>
      );
    }
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        {paginas}
      </div>
    );
  };
  

  return (
    <div className="modal-background">
      <div
        className="modal"
        style={
          foto_bg && foto_bg.length > 0
            ? {
                backgroundImage: `url(${
                  CONSTANTES.uri +
                  CONSTANTES.caminhoImageEstabelecimentos +
                  String(id) +
                  "/"
                }${foto_bg})`,
                backgroundSize: "cover" /* Cobrir toda a área com a imagem */,
                backgroundPosition: "center" /* Centralizar a imagem */,
                backgroundRepeat: "no-repeat" /* Não repetir a imagem */,
              }
            : {}
        }
      >
        <div
          className="pelicula"
          style={foto_bg && { backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          <div className="modal-fundo">
            <button className="fechar" onClick={onClose}>
              Fechar
            </button>
            <div
            // style={{
            //   backgroundColor: "red",
            //   position: "relative",
            //   width: "80%",
            //   height: "100px",
            // }}
            >
              <div
                style={{ position: "absolute", top: 10, right: 10 }}
                title="Compartilhar esse estabelecimento"
              >
                <CompartilharEstabelecimento
                  id={id}
                  secretKey={CONSTANTES.SECRET_KEY_CRIPTOGRAFIA}
                  estabelecimento={estabelecimento.nome}
                  modelo="icone"
                  borderRadius={50}
                  padding="10px"
                />
              </div>

              <div style={{ position: "absolute", top: 50, right: 50 }}>
                <ShareButton estabelecimento={estabelecimento} />
              </div>
            </div>
            {/* <a href="#" className="curtir">
              <FontAwesomeIcon
                icon={faHeart}
                size="2x"
                color={estabelecimento.favorito ? "red" : "grey"}
              />
            </a> */}
          </div>
          <div
            className="modal-conteudo"
            style={foto_bg ? { color: "white" } : {}}
          >
            <h1>{nome}</h1>
            <div className="estrelas">
              <Stars stars={star} showNumber={true} size="sm" />
            </div>
            <div className="endereco">
              <p>
                {logradouro} {numero}
              </p>
              <p>{bairro}</p>
              <p>
                {cidade} - {uf}
              </p>
              <p>{telefone && `Telefone: ${telefone}`}</p>
            </div>
            <hr />
            <div className="menu">
              <ul>
                <li
                  onClick={() => handleMenuClick(0)}
                  style={
                    menuAtivo === 0 ? { backgroundColor: COLORS.button } : {}
                  }
                >
                  Serviços
                </li>
                <li
                  onClick={() => handleMenuClick(1)}
                  style={
                    menuAtivo === 1 ? { backgroundColor: COLORS.button } : {}
                  }
                >
                  Sobre Nós
                </li>
                <li
                  onClick={() => handleMenuClick(2)}
                  style={
                    menuAtivo === 2 ? { backgroundColor: COLORS.button } : {}
                  }
                >
                  Especialistas
                </li>
                <li
                  onClick={() => handleMenuClick(3)}
                  style={
                    menuAtivo === 3 ? { backgroundColor: COLORS.button } : {}
                  }
                >
                  Galeria
                </li>
                <li
                  onClick={() => handleMenuClick(4)}
                  style={
                    menuAtivo === 4 ? { backgroundColor: COLORS.button } : {}
                  }
                >
                  Ofertas
                </li>

                <li
                  onClick={() => handleMenuClick(5)}
                  style={
                    menuAtivo === 5 ? { backgroundColor: COLORS.button } : {}
                  }
                >
                  Comentários
                </li>
              </ul>
            </div>

            {/* Box padrão para conteúdo dinâmico */}
            <div className="box-padrao">
              {menuAtivo === 0 && (
                <div className="sectionServico">
                  {liberar_agendamento ? (
                    servico && servico.length > 0 ? (
                      <ul>
                        {servico.map((service, index) => (
                          <li
                            key={index}
                            style={
                              foto_bg && foto_bg.length > 0
                                ? {
                                    backgroundColor: "rgba(0,0,0,0.7)",
                                    padding: 8,
                                    borderRadius: 5,
                                    marginBottom: 10,
                                  }
                                : {
                                    padding: 8,
                                    borderRadius: 5,
                                    marginBottom: 10,
                                    backgroundColor: "rgba(50,50,50,0.2)",
                                  }
                            }
                          >
                            <p style={styles.titulo}>{service.titulo}</p>
                            <p
                              style={{
                                ...styles.desc,
                                color: foto_bg
                                  ? COLORS.placeHolder
                                  : COLORS.cardBackground,
                              }}
                            >
                              {service.descricao}
                            </p>
                            <p>Duração: {converteSegundos(service.duracao)}</p>
                            <p>
                              A partir de{" "}
                              <span
                                style={{
                                  color: COLORS.button,
                                  fontWeight: "bold",
                                }}
                              >
                                {formatarValorReal(service.preco)}
                              </span>
                            </p>
                            <div
                              style={{
                                backgroundColor:
                                  userType === "cliente"
                                    ? COLORS.button
                                    : COLORS.bgButtonCard,
                                color: COLORS.primaryText,
                                textAlign: "center",
                                padding: "5px 0",
                                borderRadius: 5,
                                marginTop: 5,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                agendar(service.id, service.titulo);
                              }}
                            >
                              Agendar
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span
                        style={
                          foto_bg
                            ? {
                                backgroundColor: "rgba(0,0,0,0.7)",
                                padding: 10,
                                color: COLORS.primaryText,
                              }
                            : { padding: 10, color: COLORS.secondaryText }
                        }
                      >
                        Não há serviços cadastrados no momento.
                      </span>
                    )
                  ) : (
                    <span
                      style={
                        foto_bg
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              padding: 10,
                              color: COLORS.primaryText,
                            }
                          : { padding: 10, color: COLORS.secondaryText }
                      }
                    >
                      Agendamento Online não disponível.
                    </span>
                  )}
                </div>
              )}

              {menuAtivo === 1 && (
                <div className="sectionSobreNos">
                  {estabelecimento && estabelecimento.descricao ? (
                    <span
                      style={
                        foto_bg
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              padding: 10,
                              color: COLORS.primaryText,
                            }
                          : { padding: 10, color: COLORS.secondaryText }
                      }
                    >
                      {estabelecimento.descricao}
                    </span>
                  ) : (
                    <span
                      style={
                        foto_bg
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              padding: 10,
                              color: COLORS.primaryText,
                            }
                          : { padding: 10, color: COLORS.secondaryText }
                      }
                    >
                      Não há descrição sobre o estabelecimento.
                    </span>
                  )}
                </div>
              )}

              {menuAtivo === 2 && (
                <div className="sectionEspecialistas">
                  {colaborador.length > 0 ? (
                    <div style={styles.ColaboradorScroll}>
                      {colaborador &&
                        colaborador.map((item) => (
                          <div style={styles.ColaboradorItem}>
                            <img
                              src={
                                item.foto && item.foto.length > 0
                                  ? CONSTANTES.uri +
                                    CONSTANTES.caminhoImageColaboradores +
                                    item.id +
                                    "/" +
                                    item.foto
                                  : noImage
                              }
                              style={styles.ColaboradorImage}
                            />
                            <h3
                              style={{
                                ...styles.ColaboradorNome,
                                fontWeight: "bold",
                              }}
                            >
                              {item.nome}
                            </h3>
                            {/* ["2xs","xs","sm","lg","xl","2xl","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"] */}
                            <Stars
                              stars={item.star}
                              showNumber={true}
                              size="2xs"
                            />
                          </div>
                        ))}
                    </div>
                  ) : (
                    <span
                      style={
                        foto_bg
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              padding: 10,
                              color: COLORS.primaryText,
                            }
                          : { padding: 10, color: COLORS.secondaryText }
                      }
                    >
                      Não há especialistas cadastrados no momento.
                    </span>
                  )}
                </div>
              )}

              {menuAtivo === 3 && (
                <div className="sectionGaleria">
                  {galeria.length > 0 ? (
                    <ImageGallery
                      images={galeria}
                      estabelecimento_id={estabelecimento.id}
                    />
                  ) : (
                    <span
                      style={
                        foto_bg
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              padding: 10,
                              color: COLORS.primaryText,
                            }
                          : { padding: 10, color: COLORS.secondaryText }
                      }
                    >
                      Não há fotos cadastradas no momento.
                    </span>
                  )}
                </div>
              )}

              {menuAtivo === 4 && (
                <div className="sectionOfertas">
                  {oferta.length > 0 ? (
                    <OfertaList
                      lista={oferta}
                      estabelecimento_id={estabelecimento.id}
                    />
                  ) : (
                    <span
                      style={
                        foto_bg
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              padding: 10,
                              color: COLORS.primaryText,
                            }
                          : { padding: 10, color: COLORS.secondaryText }
                      }
                    >
                      Não há ofertas cadastradas no momento.
                    </span>
                  )}
                </div>
              )}

              {menuAtivo === 5 && (
                <div className="sectionComentarios">
                  {comentarios.length > 0 ? (
                    <div
                      style={
                        foto_bg
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              padding: 10,
                              color: COLORS.primaryText,
                            }
                          : { padding: 10, color: COLORS.secondaryText }
                      }
                    >
                      {comentarios.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "rgba(0,0,0,0.1)" : "",
                            borderRadius: 5,
                            padding: 15,
                            marginBottom: 15,
                            display: "flex",
                            width: "100%",
                            height: "auto",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 10,
                            }}
                          >
                            <img
                              src={
                                item.cliente.foto
                                  ? `${CONSTANTES.uri}${CONSTANTES.caminhoImageClientes}${item.cliente.id}/${item.cliente.foto}`
                                  : noImage
                              }
                              width={60}
                              height={60}
                              style={{ borderRadius: 50, marginRight: 10 }}
                            />
                            <Stars stars={item.rating} />
                          </div>
                          <div>{item.comentario}</div>
                        </div>
                      ))}

                      {/* Renderizar os botões de paginação */}
                      <div style={{ textAlign: "center", marginTop: 20 }}>
                        {renderPaginacao()}
                      </div>

                      {/* Indicador de carregamento */}
                      {loadingComentario && (
                        <div style={{ textAlign: "center", padding: 10 }}>
                          Carregando comentários...
                        </div>
                      )}
                    </div>
                  ) : (
                    <span
                      style={
                        foto_bg
                          ? {
                              backgroundColor: "rgba(0,0,0,0.7)",
                              padding: 10,
                              color: COLORS.primaryText,
                            }
                          : { padding: 10, color: COLORS.secondaryText }
                      }
                    >
                      Não há comentários sobre o estabelecimento.
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>{" "}
        </div>
      </div>

      {modalAberto && (
        <AgendamentoModal onClose={fecharModal} dados={dadosAgendar} />
      )}

      {modalAbertoLogin && (
        <LoginClienteModal onCloseLogin={fecharModalLogin} />
      )}
    </div>
  );
};

const styles = {
  titulo: {
    fontSize: "1rem",
  },
  desc: {
    fontSize: "0.9rem",
  },
  ColaboradorArea: {
    flex: 1,
    marginTop: 0,
    marginBottom: 15,
    marginRight: 20,
    marginLeft: 20,
    minHeight: 10,
    maxHeight: 280,
    padding: "20px 10px",
    overflowY: "auto", // Alterado para 'auto' ou 'scroll' para permitir o scroll vertical se necessário
    overflowX: "hidden", // Alterado para 'auto' ou 'scroll' para permitir o scroll vertical se necessário
    backgroundColor: "pink",
  },
  ColaboradorTitle: {
    color: COLORS.primaryText,
    fontSize: "1rem",
    marginBottom: 15,
  },
  ColaboradorScroll: {
    overflowY: "auto",
    overflowX: "hidden",
    minWidth: "100%", // Para garantir que ocupe toda a largura disponível
    paddingBottom: 5,
    // backgroundColor: 'red',
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
  },
  ColaboradorItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "30%", // Alterado para 'minWidth' para garantir que cada item tenha pelo menos 200px de largura
    maxWidth: "30%",
    minHeight: "160px",
    maxHeight: "160px",
    margin: "1.5%",
    borderRadius: 10,
    textDecoration: "none",
    color: COLORS.primaryText,
    overflow: "hidden",
    backgroundColor: COLORS.cardBackground,
    // backgroundColor:'pink'
  },
  ColaboradorImage: {
    width: "70px",
    height: "70px",
    borderRadius: 50,
    marginBottom: 5,
  },
  ColaboradorNome: {
    color: COLORS.primaryText,
    fontSize: "0.9rem",
    maxWidth: "280px",
    overflow: "hidden" /* Evita que o texto derrame para fora da View */,
    textAlign: "center",
    maxHeight: "70px",
  },
};

export default Estabelecimento;
