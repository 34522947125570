import React, { useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCamera,
  faChevronDown,
  faChevronUp,
  faSignOutAlt,
  faThList,
  faUsers,
  faBars,
  faUser,
  faMoneyBill,
  faImage,
  faGifts,
  faCalendarAlt,
  faImagePortrait,
  faCalendar,
  faCalendarCheck,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "./Sidebar.css";
import { estabelecimentoService } from "../../../services/estabelecimento";
import { useAuth } from "../../../contexts/AuthContext";

import { CONSTANTES } from "../../../global/CONSTANTES";
import { COLORS } from "../../../global/STYLE";
import EstabelecimentoView from "../../../components/estabelecimento/EstabelecimentoView";

const Sidebar = () => {
  const { token, logout, user, changeData } = useAuth();

  let navigate = useNavigate();

  // Inicializando um objeto de estado para controlar os dropdowns
  const [dropdownsOpen, setDropdownsOpen] = useState({});
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [carregandoBG, setCarregandoBG] = useState(false);

  const [modalAberto, setModalAberto] = useState(false);
  const [estabelecimentoID, setEstabelecimentoID] = useState(0);
  const [estabelecimento, setEstabelecimento] = useState({});


  const toggleDropdown = useCallback((dropdownName) => {
    setDropdownsOpen((prevDropdowns) => ({
      ...prevDropdowns,
      [dropdownName]: !prevDropdowns[dropdownName],
    }));
    // console.log(dropdownName)
  }, []);

  const handleNavigation = useCallback((path) => {
    // console.log(path)
    navigate(path);
    setSidebarOpen(false); // Feche o sidebar quando um item é clicado
  }, []);

  const handleImageUpload = useCallback(
    async (event) => {
      setCarregando(true);
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        const maxSize = 2 * 1024 * 1024; // 2MB em bytes

        if (file && file.size > maxSize) {
          toast.error(
            "O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor."
          );
          // alert('O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor.');
          event.target.value = ""; // Limpa o valor do input file
          setCarregando(false);
          return false;
        }

        let estabelecimento_id = user.id;
        try {
          const data = await estabelecimentoService.changePhoto(
            file,
            token,
            estabelecimento_id
          );
          if (data.error) {
            toast.error(data.message);
          } else {
            changeData("foto", data.data.foto);
            toast.success(data.message);
            // console.log(data.data.foto);
          }
        } catch (e) {
          toast.error("Erro ao enviar imagem", e.message);
          // console.error("Erro ao enviar imagem", e);
        }
      }
      setCarregando(false);
    },
    [user]
  );

  const handleImageBGUpload = useCallback(
    async (event) => {
      setCarregandoBG(true);
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        const maxSize = 2 * 1024 * 1024; // 2MB em bytes

        if (file && file.size > maxSize) {
          toast.error(
            "O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor."
          );
          // alert('O tamanho do arquivo excede o limite de 2MB. Por favor, selecione um arquivo menor.');
          event.target.value = ""; // Limpa o valor do input file
          setCarregandoBG(false);
          return false;
        }

        let estabelecimento_id = user.id;
        try {
          const data = await estabelecimentoService.changePhotoBG(
            file,
            token,
            estabelecimento_id
          );
          if (data.error) {
            toast.error(data.message);
          } else {
            // changeData("foto_bg", data.data.foto_bg);
            toast.success(data.message);
            // console.log(data.data.foto);
          }
        } catch (e) {
          toast.error("Erro ao enviar imagem", e.message);
          // console.error("Erro ao enviar imagem", e);
        }
      }
      setCarregandoBG(false);
    },
    [user]
  );

  const abrirModal = (id) => {
    setModalAberto(true);
    setEstabelecimentoID(id);
    setEstabelecimento(user)
  };

  const fecharModal = () => {
    setModalAberto(false);
  };

  return (
    <>
      <div
        className="hamburger-toggle"
        onClick={() => setSidebarOpen(!isSidebarOpen)}
      >
        <FontAwesomeIcon icon={faBars} />
      </div>
      <div
        className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}
        style={{ backgroundColor: COLORS.cardBackground }}
      >
        <div className="avatar-section">
          {user.foto ? (
            <img
              src={`${CONSTANTES.uri}${CONSTANTES.caminhoImageEstabelecimentos}${user.id}/${user.foto}`}
              alt="Avatar"
              className="avatar"
            />
          ) : (
            <img
              src="https://via.placeholder.com/100"
              alt="Avatar"
              className="avatar"
            />
          )}

          <div className="campoFotoPerfil">
            {carregando ? (
              <label className="edit-avatar">
                <span className="loader"></span>
              </label>
            ) : (
              <label htmlFor="image-upload" className="edit-avatar">
                <FontAwesomeIcon icon={faCamera} />
                <input
                  type="file"
                  id="image-upload"
                  accept="image/*"
                  onChange={handleImageUpload}
                  placeholder="BG"
                  alt="Imagem do BG do estabelecimento"
                  title="Imagem do BG do estabelecimento"
                  hidden
                />
              </label>
            )}

            {carregandoBG ? (
              <label className="edit-avatar">
                <span className="loader"></span>
              </label>
            ) : (
              <label htmlFor="imageBG-upload" className="edit-avatar">
                <FontAwesomeIcon icon={faImagePortrait} />
                <input
                  type="file"
                  id="imageBG-upload"
                  accept="image/*"
                  onChange={handleImageBGUpload}
                  hidden
                />
              </label>
            )}
          </div>
          <div className="company-name">{user.nome}</div>
        </div>
        <ul className="menu-items">
          {/* # INÍCIO */}
          <li
            className="menu-item"
            onClick={() => handleNavigation("/adminE/")}
          >
            <FontAwesomeIcon icon={faHome} className="menu-icon" />
            Início
          </li>

{/* # VISUALIZAR ESTABELECIMENTO */}
          <li
            className="menu-item"
            onClick={() => abrirModal()}
          >
            <FontAwesomeIcon icon={faEye} className="menu-icon" />
            Ver Perfil
          </li>

          {/* # AGENDAMENTO CLIENTE */}
          {user.liberar_agendamento && (
            <li
              className="menu-item"
              // onClick={() => handleNavigation("/adminE/agendamento-cliente")}
            >
              <FontAwesomeIcon icon={faCalendarCheck} className="menu-icon" />
              Agendar Cliente
            </li>
          )}

          
          
          

          {/* # PERFIL */}
          <li
            className="menu-item"
            onClick={() => handleNavigation("/adminE/perfil-estabelecimento")}
          >
            <FontAwesomeIcon icon={faUser} className="menu-icon" />
            Editar Perfil
          </li>

          {/* # AGENDAMENTO */}
          {user.liberar_agendamento && (
            <li
              className="menu-item"
              onClick={() => handleNavigation("/adminE/agendamento")}
            >
              <FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" />
              Agendamento
            </li>
          )}

          {/* # SERVIÇOS */}
          {user.liberar_agendamento && (
            <li className="menu-item">
              <div
                className="dropdown-toggle"
                onClick={() => toggleDropdown("config")}
              >
                <FontAwesomeIcon icon={faThList} className="menu-icon" />
                Serviços
                <FontAwesomeIcon
                  icon={dropdownsOpen["config"] ? faChevronUp : faChevronDown}
                  className="dropdown-icon"
                />
              </div>
              {dropdownsOpen["config"] && (
                <ul
                  className="dropdown-menu"
                  style={{ backgroundColor: COLORS.bgButtonCard }}
                >
                  <li
                    className="dropdown-item"
                    onClick={() =>
                      handleNavigation(
                        "/adminE/cadastro-servico-estabelecimento"
                      )
                    }
                  >
                    Cadastrar um Novo Serviço
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() =>
                      handleNavigation("/adminE/lista-servico-estabelecimento")
                    }
                  >
                    Listar Serviços
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => handleNavigation("/adminE/vincular-servico")}
                  >
                    Vincular Serviço (Colaborador)
                  </li>
                </ul>
              )}
            </li>
          )}

          {/* # COLABORADORES */}
          {user.liberar_agendamento && (
            <li className="menu-item">
              <div
                className="dropdown-toggle"
                onClick={() => toggleDropdown("colaboradores")}
              >
                <FontAwesomeIcon icon={faUsers} className="menu-icon" />
                Colaboradores
                <FontAwesomeIcon
                  icon={
                    dropdownsOpen["colaboradores"] ? faChevronUp : faChevronDown
                  }
                  className="dropdown-icon"
                />
              </div>
              {dropdownsOpen["colaboradores"] && (
                <ul
                  className="dropdown-menu show"
                  style={{ backgroundColor: COLORS.bgButtonCard }}
                >
                  <li
                    className="dropdown-item"
                    onClick={() =>
                      handleNavigation(
                        "/adminE/cadastro-colaborador-estabelecimento"
                      )
                    }
                  >
                    Cadastrar um novo colaborador
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() =>
                      handleNavigation(
                        "/adminE/lista-colaboradores-estabelecimento"
                      )
                    }
                  >
                    Listar colaboradores
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() =>
                      handleNavigation(
                        "/adminE/horario-disponivel-colaborador-estabelecimento"
                      )
                    }
                  >
                    Horários disponíveis
                  </li>
                </ul>
              )}
            </li>
          )}

          {/* # GALERIA */}
          {user.limite_imagem > 0 && (
            <li
              className="menu-item"
              onClick={() =>
                handleNavigation("/adminE/galeria-estabelecimento")
              }
            >
              <FontAwesomeIcon icon={faImage} className="menu-icon" />
              Galeria
            </li>
          )}

          {/* # OFERTAS */}
          {user.liberar_oferta && (
            <li
              className="menu-item"
              onClick={() => handleNavigation("/adminE/oferta-estabelecimento")}
            >
              <FontAwesomeIcon icon={faGifts} className="menu-icon" />
              Ofertas
            </li>
          )}

          {/* # PAGAMENTO */}
          {!user.vitalicio && (
            <li className="menu-item">
              <div
                className="dropdown-toggle"
                onClick={() => toggleDropdown("pagamento")}
              >
                <FontAwesomeIcon icon={faMoneyBill} className="menu-icon" />
                Pagamento
                <FontAwesomeIcon
                  icon={
                    dropdownsOpen["pagamento"] ? faChevronUp : faChevronDown
                  }
                  className="dropdown-icon"
                />
              </div>
              {dropdownsOpen["pagamento"] && (
                <ul
                  className="dropdown-menu show"
                  style={{ backgroundColor: COLORS.bgButtonCard }}
                >
                  <li
                    className="dropdown-item"
                    onClick={() => handleNavigation("/adminE/lista-pagamento")}
                  >
                    Listagem de Pagamentos
                  </li>
                  {/* <li
                  className="dropdown-item"
                  onClick={() =>
                    handleNavigation("/adminE/listaColaboradorEstabelecimento")
                  }
                >
                  Listar colaboradores
                </li> */}
                </ul>
              )}
            </li>
          )}
        </ul>

        {/* # LOGOUT */}
        <div className="logout-section">
          <button
            className="logout-button"
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
            Sair
          </button>
        </div>
      </div>

      {modalAberto && (
        <EstabelecimentoView
          id={estabelecimentoID}
          data={user}
          onClose={fecharModal}
          viewEstab={true}
        />
      )}
    </>
  );
};

export default Sidebar;
